import classNames from 'classnames'
import React from 'react'
import {HeaderStoryblok} from 'types/storyblok-types'
import {storyblokEditable} from '@storyblok/react'
import {CachedServiceDeliveryPoint} from 'types/types'
import {replaceWithSdplData} from 'util/replaceWithSdplData'

export enum HeaderType {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

type HeaderProps = {
  title: HeaderStoryblok['title']
  type: HeaderType
  alignment?: HeaderStoryblok['aligment']
  withoutBottomMargin?: boolean
  includeTopMargin?: boolean
}

export const HeaderBlock = ({
  title = 'Title',
  type,
  alignment = 'left',
  withoutBottomMargin = false,
  includeTopMargin = false,
}: HeaderProps) => {
  const RenderedHeaderType = type

  const headerClass = classNames('font-rubik pb-0 mb-0', {
    'text-[32px] font-bold leading-[38px] text-dats-s6': type === HeaderType.h1,
    'text-2xl font-bold text-dats-s5': type === HeaderType.h2,
    'text-lg font-medium text-dats-s5': type === HeaderType.h3,
    'text-base font-medium text-dats-s5': type === HeaderType.h4,
    'text-sm font-medium text-dats-s5': type === HeaderType.h5,
    'text-sm font-bold text-dats-s5': type === HeaderType.h6,
    'text-left': alignment === 'left',
    'text-center': alignment === 'center',
  })

  const marginHandler = classNames({
    'mt-8': includeTopMargin && withoutBottomMargin,
    'mt-8 mb-4': includeTopMargin && !withoutBottomMargin,
    'mb-4': !withoutBottomMargin,
  })

  return (
    <div className={`${marginHandler} w-full`}>
      <RenderedHeaderType className={`${headerClass} custom-break-word`}>
        {title}
      </RenderedHeaderType>
    </div>
  )
}

type HeaderContainerProps = {
  blok: HeaderStoryblok
  withoutBottomMargin?: boolean
  includeTopMargin?: boolean
  sdpl?: CachedServiceDeliveryPoint
}
const HeaderContainer = ({
  blok,
  withoutBottomMargin,
  includeTopMargin,
  sdpl,
}: HeaderContainerProps) => {
  let text = blok.title ?? ''

  if (sdpl) {
    text = replaceWithSdplData(sdpl, text)
  }

  const toHeaderType = (headerType: string) => {
    if (headerType === 'h1') {
      return HeaderType.h1
    } else if (headerType === 'h2') {
      return HeaderType.h2
    } else if (headerType === 'h3') {
      return HeaderType.h3
    } else if (headerType === 'h4') {
      return HeaderType.h4
    } else if (headerType === 'h5') {
      return HeaderType.h5
    } else {
      return HeaderType.h6
    }
  }

  return (
    <div {...storyblokEditable(blok)}>
      <HeaderBlock
        title={text}
        type={toHeaderType(blok.header_type ?? 'h2')}
        alignment={blok.aligment ?? 'left'}
        withoutBottomMargin={withoutBottomMargin ?? false}
        includeTopMargin={includeTopMargin ?? false}
      />
    </div>
  )
}
export default HeaderContainer
