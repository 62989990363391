import {CachedServiceDeliveryPoint} from 'types/types'

export const replaceWithSdplData = (
  sdpl: CachedServiceDeliveryPoint,
  text: string | undefined,
) => {
  if (!text) {
    return ''
  }

  const valuesToReplace = {
    city: sdpl?.detail_data?.city!,
    name: sdpl?.overview_data.fuelStation?.name!,
    address: sdpl?.detail_data?.address!,
    street: sdpl?.detail_data?.street!,
  }

  return text.replace(/\{(\w+)\}/g, function (_, key) {
    return valuesToReplace[key as keyof typeof valuesToReplace] || ''
  })
}
